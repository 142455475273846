<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap py-5>
      <v-flex xs12 text-left px-3>
        <span class="itemHeading">Slots</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex md6 xs12 pa-3 py-3 v-for="item in slots" :key="item._id">
        <v-layout wrap justify-center>
          <v-flex md12 align-self-center>
            <v-card height="50px" color="#68D389">
              <v-layout wrap justify-center py-4>
                <v-flex md3 align-self-center>
                  <h4 style="color: #ffffff; font-family: poppinssemibold">
                    <span>
                      <router-link :to="'/editSlot/' + item._id + '/' + id">
                        <v-icon
                          color="#ffffff"
                          style="font-size: 22px; font-family: poppinsbold"
                        >
                          mdi-circle-edit-outline
                        </v-icon>
                      </router-link>
                    </span>
                  </h4>
                </v-flex>
                <v-flex md8 text-right>
                  <span style="color: #ffffff; font-family: poppinssemibold">
                    {{ item.fromDate.slice(0, 15) }} to
                    {{ item.toDate.slice(0, 15) }}</span
                  >
                </v-flex>
                <v-flex xs1>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Slot?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteSlot(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex md12 xs12 align-self-center>
            <v-card>
              <v-layout
                py-2
                v-for="(subitem, index) in item.slotDetails"
                :key="index"
                wrap
                justify-center
              >
                <v-flex md6 align-self-center>
                  <span>
                    {{ formatTime(subitem.startTime) }} -
                    {{ formatTime(subitem.endTime) }}
                  </span>
                </v-flex>
                <v-flex md6 align-self-center v-if="program.isCottage">
                  <span style="font-family: poppinsRegular">
                    {{ subitem.availableNo }} Rooms Available</span
                  >
                </v-flex>
                <v-flex v-else md6 align-self-center>
                  <span style="font-family: poppinsRegular">
                    {{ subitem.availableNo }} Seats Available</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      // program: {},
      pricing: [],
      slots: [],
      program: [],
      packageView: false,
      slotView: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.slots = response.data.slots;
            this.program = response.data.data;
            for (var j = 0; j < this.slots.length; j++) {
              var fromDate1 = new Date(this.slots[j].fromDate).toString();
              var toDate1 = new Date(this.slots[j].toDate).toString();
              this.slots[j].fromDate = fromDate1;
              this.slots[j].toDate = toDate1;
            }
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteSlot(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/programme/slot/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>